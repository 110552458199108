import Vue from "vue";
import VueRouter from "vue-router";
import detectDevice from "../utils/detectDevice";
Vue.use(VueRouter);

const isMobile = detectDevice();
console.log("是否移动设备" + isMobile);

const routes = [
  //  {
  //     path: "/web",
  //     name: "web",
  //     redirect: "web",
  //     component: () => import("../views/Layout/index.vue"),
  //     children: [
  //         {
  //             path: "meoo",
  //             name: "meoo",
  //             meta: { title: 'Meoo Live' },
  //             component: () => import("../web/meoo.vue")
  //         },
  //     ]
  // }
  {
    path: "/",
    name: "web",
    meta: { title: "Meoo Live" },
    component: () =>
      isMobile ? import("../web/meooMobile.vue") : import("../web/meooPc.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
