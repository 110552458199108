function detectDevice() {
  // 获取userAgent字符串
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log(userAgent);

  // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
  // 这些关键字通常与移动设备相关
  var mobile =
    /windows phone|iphone|ipad|ipod|tablet|android|blackberry|mini|windows ce|palm|pixel\s?\d+/i.test(
      userAgent.toLowerCase()
    );

  if (mobile) {
    // 如果userAgent包含上述关键字之一，则认为是在移动设备上
    return true;
  } else {
    // 否则，认为是在PC（桌面设备）上
    return false;
  }
  // 获取屏幕宽度从而分辨手机电脑
  // if (window.screen.width <= 768) {
  //   return true;
  // } else {
  //   return false;
  // }
}

export default detectDevice;
